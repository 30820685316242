import { State } from './state'
import { IArea } from '@/classes/Area/ITicket'

export default {
  SET_AREAS (state: State, areas: IArea[]) {
    state.areas = areas
  },
  PUSH_AREA (state: State, area: IArea) {
    state.areas.push(area)
  }
}
