export async function trycatchAsync (func: () => any, fail: any) {
  try {
    return await func()
  } catch (e) {
    return fail
  }
}

export function trycatchSync (func: () => any, fail: any) {
  try { return func() } catch (e) { console.log(e); return fail }
}

export function trycatch (func: () => any, fail: any) {
  try {
    return func()
  } catch (e) {
    return fail
  }
}
