<template>
  <transition name="fade">
    <div class="loaderDialog" v-show="$store.state.app.loader.loader">
      <div class="loaderContainer">
        <v-img :src="getUrlLoader" width="450"></v-img>
        <!-- <SUDLogoLoader class="sudLogoLoader"></SUDLogoLoader> -->
        <div class="loaderInfoText ">
          <div class="loader"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue'
// import SUDLogoLoader from '@/components/SUDLogoLoader.vue'

export default Vue.extend({
  name: 'LoaderDialog',
  components: {
    // SUDLogoLoader
  },
  computed: {
    getUrlLoader () {
      // if (this.$store.state.auth.user_date.loader_image) {
      //   return `${process.env.VUE_APP_BASE_URL || ''}/media/${this.$store.state.auth.user_date.loader_image}`
      // }
      return (require('../../public/ANANTA_HORIZONTAL.png'))
      // return (path) => {
      //   // eslint-disable-next-line @typescript-eslint/no-var-requires
      //   console.log(require('../../.env.files/logo_l.png'))
      //   if (!path) return require('../../.env.files/logo_l.png')
      //   return `${process.env.VUE_APP_BASE_URL || ''}/${path}?time${Date.now()}`
      // }
    }
  }
})
</script>

<style lang="sass" scoped>

.loaderDialog
  position: fixed
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(255,250,250, 1)
  z-index: 1000
  overflow: hidden

  .loaderContainer
    width: 75%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

  .d-flex
    width: 100%
    display: flex
    justify-content: center
    align-items: center

  .sudLogoLoader
    width: 100%

  .loaderInfoText
    text-align: center
    color: black
    font-size: 25px
    font-weight: 100

  .loader
    width: 90px
    height: 60px
    box-shadow: 0 3px 0 #000 /* Ajustado a color oscuro */
    position: relative
    clip-path: inset(-40px 0 -5px)
    background-position: left, center, right
    background-repeat: no-repeat
    animation: l10 1s infinite alternate

  .loader:before
    content: ""
    position: absolute
    inset: auto calc(50% - 17px) 0
    height: 50px
    --g: no-repeat linear-gradient(#444 0 0)
    background: var(--g), var(--g), var(--g), var(--g)
    background-size: 16px 14px
    animation: l7-1 2s infinite linear, l7-2 2s infinite linear

  @keyframes l7-1
    0%, 100%
      background-position: 0 -50px, 100% -50px
    17.5%
      background-position: 0 100%, 100% -50px, 0 -50px, 100% -50px
    35%
      background-position: 0 100%, 100% 100%, 0 -50px, 100% -50px
    52.5%
      background-position: 0 100%, 100% 100%, 0 calc(100% - 16px), 100% -50px
    70%, 98%
      background-position: 0 100%, 100% 100%, 0 calc(100% - 16px), 100% calc(100% - 16px)

  @keyframes l7-2
    0%, 70%
      transform: translate(0)
    100%
      transform: translate(200%)

.fade-enter-active,
.fade-leave-active
  transition: opacity 0.5s

.fade-enter,
.fade-leave-to /* .fade-leave-active en versiones anteriores a 2.1.8 */
  opacity: 0
</style>
