import { ActionContext } from 'vuex'
import { State } from './state'
import ChecklistModule from '@/classes/Checklist/ChecklistModules'

const URL_ACTION = '/apis/checklist_modules/'
const actions = {
  async loadAll ({ dispatch, rootState, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}get/too`,
          method: 'GET'
        },
        { root: true }
      )
      const modules = data.map((module: any) => new ChecklistModule(module))
      commit('SET_ALL', modules)
    } catch (error) {
      console.error('Error al obtener los módulos de lista de verificación:', error)
    }
  },
  async create ({ dispatch, rootState, commit }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}create`,
          method: 'POST',
          data: payload
        },
        { root: true }
      )
      commit('PUSH', data)
      await dispatch('app/notification', { content: 'Éxito', type: 'success' }, { root: true })
    } catch (error) {
      console.error(error)
      await dispatch('app/notification', { content: 'No se ha podido guardar el módulo de lista de verificación', type: 'error' }, { root: true })
      dispatch('app/addLogWithError', { title: 'ERROR AL CREAR MÓDULO DE CHECKLIST', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async update ({ dispatch, rootState, commit }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}update/${payload.id}`,
          method: 'PUT',
          data: payload
        },
        { root: true }
      )
      commit('UPDATE', data)
      await dispatch('app/notification', { content: 'Éxito', type: 'success' }, { root: true })
    } catch (error) {
      console.error(error)
      await dispatch('app/notification', { content: 'No se ha podido actualizar el módulo de lista de verificación', type: 'error' }, { root: true })
      dispatch('app/addLogWithError', { title: 'ERROR AL ACTUALIZAR MÓDULO DE CHECKLIST', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async delete ({ commit, dispatch }: ActionContext<State, string>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}delete/${payload}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE', payload)
  }
}
export default actions
