
import { ActionContext } from 'vuex'
import { State } from './state'
// import { IProducto } from '@/classes/Facturacion/IProduct'
const URL_ACTION = '/apis/user/'
const actions = {
  async loadAll ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION,
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async loadUserByAccount ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'get/users',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_ALL', data)
    return data
  },
  async loadById ({ commit, dispatch }: ActionContext<State, any>, id:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${id}`,
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async update ({ commit, dispatch }: ActionContext<State, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async create ({ commit, dispatch }: ActionContext<State, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async getToken ({ commit, dispatch }: ActionContext<State, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}get-token`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async delete ({ commit, dispatch }: ActionContext<State, any>, id:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async treeView ({ commit, dispatch }: ActionContext<State, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}tree-view`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  }
  // async createProduct ({ commit, dispatch }: ActionContext<State, any>, payload: IProducto) {
  //   const { data } = await dispatch(
  //     'app/axios',
  //     {
  //       url: URL_ACTION,
  //       method: 'POST',
  //       data: payload
  //     },
  //     { root: true }
  //   )
  //   commit('PUSH', data)
  // },
  // async updateProduct ({ commit, dispatch }: ActionContext<State, any>, payload: IProducto) {
  //   const { data } = await dispatch(
  //     'app/axios',
  //     {
  //       url: `${URL_ACTION}${payload.id}`,
  //       method: 'PUT',
  //       data: payload
  //     },
  //     { root: true }
  //   )
  //   commit('UPDATE', data)
  // }
}
export default actions
