var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$store.state.app.loader.loader,
      expression: "$store.state.app.loader.loader"
    }],
    staticClass: "loaderDialog"
  }, [_c('div', {
    staticClass: "loaderContainer"
  }, [_c('v-img', {
    attrs: {
      "src": _vm.getUrlLoader,
      "width": "450"
    }
  }), _c('div', {
    staticClass: "loaderInfoText"
  }, [_c('div', {
    staticClass: "loader"
  })])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }