import { ActionContext } from 'vuex'
import { State } from './state'
import ChecklistTask from '@/classes/Checklist/Tasks'

const URL_ACTION = '/apis/tasks/'

const actions = {
  async loadAll ({ dispatch, rootState, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}get/all`,
          method: 'GET'
        },
        { root: true }
      )
      const tasks = data.map((task: any) => new ChecklistTask(task))
      commit('SET_ALL', tasks)
    } catch (error) {
      console.error('Error al obtener las tareas de la lista de verificación:', error)
      dispatch('app/addLogWithError', { title: 'Error al obtener las tareas de la lista de verificación', color: 'error', message: '', error }, { root: true })
    }
  },
  async loadAssigned ({ dispatch, rootState, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}get/assigned`,
          method: 'GET'
        },
        { root: true }
      )
      const tasks = data.map((task: any) => new ChecklistTask(task))
      commit('SET_ASSIGNED_TASKS', tasks)
    } catch (error) {
      dispatch('app/addLogWithError', { title: 'Error al obtener las tareas asignadas de la lista de verificación', color: 'error', message: '', error }, { root: true })
    }
  },
  async loadTemplates ({ dispatch, rootState, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}get/templates`,
          method: 'GET'
        },
        { root: true }
      )
      commit('SET_TEMPLATE', data)
    } catch (error) {
      dispatch('app/addLogWithError', { title: 'Error al obtener las tareas asignadas de la lista de verificación', color: 'error', message: '', error }, { root: true })
    }
  },
  async create ({ dispatch, rootState, commit }: ActionContext<State, string>, payload: ChecklistTask) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}create`,
          method: 'POST',
          data: payload
        },
        { root: true }
      )
      commit('PUSH', data)
      await dispatch('loadTemplates')
    } catch (error) {
      dispatch('app/addLogWithError', { title: 'Error al crear la tarea', color: 'error', message: '', error }, { root: true })
    }
  },
  async update ({ dispatch, rootState, commit }: ActionContext<State, string>, payload: ChecklistTask) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}update/${payload.id}`,
          method: 'PUT',
          data: payload
        },
        { root: true }
      )
      commit('UPDATE', data)
      await dispatch('loadAssigned')
      await dispatch('loadTemplates')
      await dispatch('app/notification', { content: 'Tarea Actualizada', type: 'success' }, { root: true })
    } catch (error) {
      dispatch('app/addLogWithError', { title: 'Error al actualizar la tarea', color: 'error', message: '', error }, { root: true })
    }
  },
  async delete ({ dispatch, rootState, commit }: ActionContext<State, string>, taskId: number) {
    try {
      await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}delete/${taskId}`,
          method: 'DELETE'
        },
        { root: true }
      )
      commit('DELETE', taskId)
      await dispatch('app/notification', { content: 'Tarea Eliminada', type: 'success' }, { root: true })
    } catch (error) {
      dispatch('app/addLogWithError', { title: 'Error al eliminar la tarea', color: 'error', message: '', error }, { root: true })
    }
  }
}

export default actions
