/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import AssingData from '../../utils/AssingData'
// import { IParameters } from '../../interfaces/parameters.interface'
// import { Optional } from '../../interfaces/utils.interface'
// import { isEqual } from 'lodash'
// import Action from './Action'
// import {
//   Ambienttemp,
//   Ignition,
//   ApplicationField,
//   AvgFuelConsumption,
//   BatteryVoltage,
//   Can,
//   Connected,
//   Device,
//   Drivers,
//   Fuel,
//   Fuel_Tank,
//   InObjects,
//   IoDin,
//   MovementState,
//   Reefer,
//   Relay,
//   State,
//   SupplyVoltage,
//   Tachograph,
//   Temperature,
//   Unit,
//   Weights,
//   BatteryLevelPercentage,
//   Driver
// } from '../../interfaces/unit.interface'
// import { ActionConstructor, IAction } from '../../interfaces/actions.interface'
import { Unit } from '@/interfaces/unit.interface'
import StreamaxJSSdk from '..'
import Action from './Action'
import { ActionConstructor } from '@/interfaces/actions.interface'
// import { ActionConstructor } from 'dist/interfaces/actions.interface'

// export type IListUnitParams = Optional<
//   Pick<IParameters, 'include' | 'car_number' | 'empty_box_id' | 'unit_id'>,
//   'unit_id' | 'include' | 'empty_box_id' | 'car_number'
// > & {
//   include?: [
//   | 'in_objects'
//   | 'io_din'
//   | 'fuel'
//   | 'fuel_tank'
//   | 'can'
//   | 'reefer'
//   | 'drivers'
//   | 'temperature'
//   | 'ambienttemp'
//   | 'device'
//   | 'supply_voltage'
//   | 'battery_voltage'
//   | 'battery_level_percentage'
//   | 'relays'
//   | 'weights'
//   | 'ignition'
//   | 'tachograph'
//   | 'altitude'];
// };

export default class AUnit extends Action<Unit> {
      // public unit_id!: number;
      public acc!: number; // Asumiendo que es un número, podría representar la aceleración o si el vehículo está en movimiento (1) o estático (0).
      public angle!: number; // Representa el ángulo o dirección del movimiento.
      public hdop!: number; // Horizontal Dilution of Precision, un indicador de la precisión de la posición horizontal.
      public id!: string; // Un identificador único para este conjunto de datos GPS.
      public lat!: number; // La latitud en un formato específico, parece estar multiplicada por 1000000 para mantener la precisión sin usar flotantes.
      public lng!: number; // La longitud, con un formato similar al de la latitud.
      public mileage!: number; // El kilometraje acumulado.
      public numOfSatellites!: number; // El número de satélites en uso para determinar la posición.
      public signalStrength!: number; // La fuerza de la señal, cuyo significado exacto podría depender del contexto específico del dispositivo o la red.
      public speed!: number; // La velocidad actual.
      public time!: number; // El tiempo en formato de timestamp (milisegundos desde la época de Unix, 1 de enero de 1970).
      public uniqueId!: string; // Un identificador único para el dispositivo que reportó los datos GPS.
      public vehicleId!: string; // Un identificador único para el vehículo.
      //   public direction!: number;
      //   public speed?: number | undefined;
      //   public mileage!: number;
      public last_update!: string;
      //   public ignition_total_time!: number;
      //   public state!: State;
      //   public ignition?: Ignition | undefined;
      //   public movement_state!: MovementState;
      //   public fuel_type!: string;
      //   public avg_fuel_consumption!: AvgFuelConsumption;
      //   public created_at!: string;
      //   public supply_voltage?: SupplyVoltage | undefined;
      //   public battery_voltage?: BatteryVoltage | undefined;
      //   public battery_level_percentage?: BatteryLevelPercentage | undefined;
      //   public device?: Device | undefined;
      //   public io_din?: IoDin[] | undefined;
      //   public connected?: Connected | undefined;
      //   public fuel?: Fuel[] | undefined;
      //   public fuel_tank?: Fuel_Tank | undefined;
      //   public in_objects?: InObjects | undefined;
      //   public temperature?: Temperature | undefined;
      //   public ambienttemp?: Ambienttemp | undefined;
      //   public can?: Can | undefined;
      //   public weights?: Weights | undefined;
      //   public reefer?: Reefer | undefined;
        public drivers?: [] | undefined;
        //   public relays?: Relay[] | undefined;
        //   public application_fields?: ApplicationField[] | undefined;
        //   public tachograph?: Tachograph | undefined;

  public subscribedEventsCount = 0;
  public static unit_ids: string[] = [];

  // ========================== Static methods =============================================

  public static async observeMessages () {
    const unit_ids = StreamaxJSSdk.actions.AUnit.unit_ids.join(', ')
    // @ts-ignore
    const units_data = await StreamaxJSSdk.actions.AUnit.list({ uniqueIds: unit_ids }, { raw: true })
    for (let i = 0; i < units_data.length; i++) {
      const unit_data = units_data[i]
      StreamaxJSSdk.event.emit(StreamaxJSSdk.actions.AUnit.getChangeEventNameUnit(unit_data.uniqueId), unit_data)
    }
  }

  public static susbscribeUnitToData (unit_id: string) {
    if (!AUnit.unit_ids.includes(unit_id)) {
      AUnit.unit_ids.push(unit_id)
    }
  }

  public static unsusbscribeUnitToData (unit_id: string) {
    const index = AUnit.unit_ids.findIndex((id) => id === unit_id)
    if (index !== -1) {
      AUnit.unit_ids.splice(index, -1)
    }
  }

  //   public static async getByIds (
  //     unit_ids: number[],
  //     params: IListUnitParams = {}
  //   ) {
  //     // @ts-ignore
  //     const units = await AUnit.list({ unit_id: unit_ids, ...params })
  //     return units
  //   }

  //   public static async getById (unit_id: number, params: IListUnitParams = {}) {
  //     // @ts-ignore
  //     const unit = await AUnit.list({ unit_id: unit_id, ...params })
  //     if (unit.length) {
  //       return unit[0]
  //     } else {
  //       return null
  //     }
  //   }

  public static getChangeEventNameUnit (Id: string | number) {
    return `ACTION_CHANGE_UNIT_${Id}`
  }

  //   // ========================================================================================

  constructor (config: ActionConstructor) {
    super(config)
    StreamaxJSSdk.event.addListener(this.CHANGE_EVENT, (data: Unit) => {
      const updated = this.setData({ data })
      if (updated) {
        StreamaxJSSdk.event.emit(`${this.uniqueId}_MESSAGE_CHANGED`, data)
      }
    })
  }

  public isUpdated (unit: Unit): boolean {
    return this.time !== unit.time
  }

  public getIdStr () {
    return `UNIT_${this.uniqueId}`
  }

  public get getId () {
    return this.uniqueId
  }

  //   public getName () {
  //     return this.number
  //   }

  public static async list (params = {}, { raw } = { raw: false }): Promise<AUnit[]> {
    const { data } = await StreamaxJSSdk.axiosV3({
      url: '/gps/newest',
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      params
    })
    if (!raw) {
      const aUnits = data.data.map((unit: any) => new AUnit({ data: unit }))
      return aUnits
    } else {
      // @ts-ignore
      return data.data
    }
  }

  //   public static async toogleDriver ({ driver_id, unit_id }: { driver_id: number | string; unit_id:number | string }): Promise<boolean> {
  //     const res = await super.AEdit<{ status: 'ok' }>({ driver_id, unit_id }, { action: 'unit' })
  //     if (res.data.status === 'ok') {
  //       return true
  //     }
  //     return false
  //   }

  public addEventListener (type: 'MESSAGE_CHANGED' | 'POSITION_CHANGED' | 'DRIVERS_CHANGED', callback: (data: Unit) => void) {
    this.subscribedEventsCount++
    AUnit.susbscribeUnitToData(this.uniqueId)
    switch (type) {
      case 'MESSAGE_CHANGED':
        StreamaxJSSdk.event.addListener(`${this.uniqueId}_MESSAGE_CHANGED`, callback)
        break
      case 'POSITION_CHANGED':
        StreamaxJSSdk.event.addListener(`${this.uniqueId}_POSITION_CHANGED`, callback)
        break
      case 'DRIVERS_CHANGED':
        StreamaxJSSdk.event.addListener(`${this.uniqueId}_DRIVERS_CHANGED`, callback)
        break
    }
  }

  public removeEventListener (type: 'MESSAGE_CHANGED' | 'POSITION_CHANGED' | 'DRIVERS_CHANGED', callback: (data: Unit) => void) {
    this.subscribedEventsCount--
    if (this.subscribedEventsCount <= 0) {
      AUnit.unsusbscribeUnitToData(this.uniqueId)
    }
    switch (type) {
      case 'MESSAGE_CHANGED':
        StreamaxJSSdk.event.removeListener(`${this.uniqueId}_MESSAGE_CHANGED`, callback)
        break
      case 'POSITION_CHANGED':
        StreamaxJSSdk.event.removeListener(`${this.uniqueId}_POSITION_CHANGED`, callback)
        break
      case 'DRIVERS_CHANGED':
        StreamaxJSSdk.event.removeListener(`${this.uniqueId}_DRIVERS_CHANGED`, callback)
        break
    }
  }

  //   public get googleLink () {
  //     return `http://maps.google.com/?q=${this.lat},${this.lng}`
  //   }

  //   public get getLastMessage (): Unit | null {
  //     return this.lastData
  //   }

  //   public getIcon () {
  //     return this.lastData?.icon
  //   }

  //   public get getCoordinates () {
  //     return `${this.lastData?.lat},${this.lastData?.lng}`
  //   }

  //   public get getCoordinatesRaw () {
  //     return { x: this.lastData?.lat, y: this.lastData?.lng }
  //   }

  //   public get getDriversList () {
  //     return this.lastData?.drivers ? Object.values(this.lastData?.drivers) : []
  //   }

  protected onDataUpdated (prevData: Unit | null, lastData: Unit): void {
    if (prevData) {
      if (prevData?.lat !== lastData.lat || prevData?.lng !== lastData.lng) {
        StreamaxJSSdk.event.emit(`${this.uniqueId}_POSITION_CHANGED`, lastData)
      }
    //   if (!isEqual(prevData?.drivers, lastData.drivers)) {
    //     StreamaxJSSdk.event.emit(`${this.unit_id}_DRIVERS_CHANGED`, lastData)
    //   }
    }
  }

  //   public getGPSBateryPercentage () {
  //     if (!this.lastData?.battery_level_percentage?.value) return null
  //     return this.lastData?.battery_level_percentage?.value
  //   }

  //   public getGPSBatery () {
  //     if (!this.lastData?.battery_voltage?.value) return null
  //     return this.lastData?.battery_voltage?.value
  //   }

  //   public getUnitBatery () {
  //     if (!this.lastData?.supply_voltage?.value) return null
  //     return this.lastData?.supply_voltage?.value
  //   }

//   public getDrivers (): Driver[] {
//     const drivers = Object.values(this.lastData?.drivers || {})
//     return drivers
//   }
}
