import { Module } from 'vuex'
// @ts-ignore
import { RootState } from '@/store/types'
import { productos } from './modules/product'
import { customer } from './modules/customer'
import { quotation } from './modules/quotations'
import { note_sale } from './modules/note_sale'
// import { productos } from './productos'
// import { clientes } from './clientes'
// import { cotizaciones } from './cotizaciones'
import { factura } from './modules/factura/index'
import { pago } from './modules/pago/index'

export const facturacion: Module<any, RootState> = {
  namespaced: true,
  modules: {
    productos,
    customer,
    quotation,
    note_sale,
    factura,
    pago
    // clientes,
    // cotizaciones
  }
}
