import Root from '../Root/Root'

export enum RNRESOURCES_TYPES {
  IMAGE = 1,
  VIDEO = 2,
  HV_PICTURE_ID = 3,
  HV_VIDEO_ID = 4,
}

export interface RNResource {
  name: string;
  url: string;
  icon?: string;
  type: RNRESOURCES_TYPES;
}

export interface INotification {
  id_unit: number
  unit_name: string
  cam_id: string
  notification: string
  message: string
  txt: string
  date: string | null
  icon: string | undefined
  color: string
  urlMaps: string | null
  view?: boolean
  resources?: RNResource[]
  notification_child?: INotification[]
}

export default class Notification extends Root implements INotification {
  id_unit!: number
  unit_name!: string
  notification!: string
  cam_id!: string
  message!: string
  txt!: string
  date!: string
  icon!: string | undefined
  color!: string
  urlMaps!: string | null
  view?: boolean
  resources?: RNResource[]
  notification_child?: Notification[]

  constructor (notification: INotification) {
    super()
    Object.assign(this, notification)
    this.view = false
    this.notification_child = []
  }
}
