import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyD-GPPaWxJlyLJU4AFijY1UqOqSPJCqb0w',
  authDomain: 'monitoreo-y-control-67bac.firebaseapp.com',
  projectId: 'monitoreo-y-control-67bac',
  storageBucket: 'monitoreo-y-control-67bac.appspot.com',
  messagingSenderId: '1064732061694',
  appId: '1:1064732061694:web:12aa5be655d904a6565aaf',
  measurementId: 'G-W3TJVG0FHK'
}

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)
// function listenNotif () {
//   onMessage(messaging, (payload) => {
//     console.log('Message received. ', payload)
//     // ...
//   })
// }
async function requestPermission (): Promise<void> {
  try {
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      console.log('Notification permission granted.')
    }
  } catch (error) {
    console.error('Error requesting permission:', error)
  }
}

async function fetchToken (): Promise<string | null> {
  try {
    const currentToken = await getToken(messaging, { vapidKey: 'BHstEapm_s6oEImA9anEXfTATkIxt-3X7Skn7z07H5ZHy74_mNwjNnwxaqZ9KZRXhssHN5nID7JJmycjUvQSyLU' })
    if (currentToken) {
      return currentToken
    } else {
      console.log('No registration token available.')
      return null
    }
  } catch (error) {
    console.error('Error retrieving token:', error)
    return null
  }
}

export { messaging, requestPermission, fetchToken }
