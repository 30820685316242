import { IIssue } from '@/classes/Issue/IIssue'
import { State } from './state'

export default {
  SET_ISSUES (state: State, issues: IIssue[]) {
    state.issues = issues
  },
  PUSH_ISSUES (state: State, issue: IIssue) {
    state.issues.push(issue)
  },
  UPDATE_ISSUE (state: State, updatedIssue: IIssue) {
    const index = state.issues.findIndex(issue => issue.id === updatedIssue.id)
    if (index !== -1) {
      // Actualiza el elemento en la posición 'index' del array 'issues'
      state.issues.splice(index, 1, updatedIssue)
    }
  }
}
