import { InventoryState } from '../../state'
import { IProviderInventory } from '../../../../classes/Inventory/interfaces/IProviderInventory'
import { IRequisition } from '../../../../classes/Inventory/interfaces/IRequisition'

export default {
  SET_REQUISITION (state: InventoryState, entities: IRequisition[]): void {
    state.requisition = entities
  },
  PUSH_REQUISITION (state: InventoryState, entitie: IRequisition): void {
    state.requisition.unshift(entitie)
  },
  UPDATE_REQUISITION (state: InventoryState, entitie: IRequisition): void {
    const index = state.requisition.findIndex(p => p.id === entitie.id)
    if (index !== -1) {
      state.requisition.splice(index, 1, entitie)
    }
  },
  DELETE_REQUISITION (state: InventoryState, entitie: IRequisition): void {
    const index = state.requisition.findIndex(p => p.id === entitie.id)
    if (index !== -1) {
      state.requisition.splice(index, 1)
    }
  }
}
