/* eslint-disable @typescript-eslint/no-unused-vars */
// import { IObject } from '../../interfaces/Object.interface'
// import { IParameters } from '../../interfaces/parameters.interface'
// import { Optional } from '../../interfaces/utils.interface'
import Action from './Action'
// import { Alert } from '../../interfaces/Alert.interface'
import { DateTime } from 'luxon'
import MaponSDK from '../..'
import { Alert } from '@/interfaces/Alert.interface'
import StreamaxJSSdk from '..'
import { RequestData } from '@/interfaces/RequestsWrapper.interface'
import { QueryHistoryAlarmDTO } from '@/interfaces/Gps.interface'

// export type IListUnitParams = Optional<
//   Pick<
//     IParameters,
//     | 'key'
//     | 'from'
//     | 'till'
//     | 'unit_id'
//     | 'alert_type'
//     | 'driver'
//     | 'include'
//     | 'limit'
//     | 'page'
//   >,
//   'key' | 'unit_id' | 'alert_type' | 'driver' | 'include' | 'limit' | 'page'
// >;

// @AssingData()
export default class AAlert extends Action<Alert> implements Alert {
  alarmId!: string
  alarmType!: number
  gps!: { angle: number; lat: number; lng: number; speed: number; time: string }
  startTime!: string
  uniqueId!: string
  vehicleNumber!: string
  vehicleId!: string

  // unit_id!: number;
  // time!: Date;
  // alert_type!: string;
  // alert_val!: string;
  // msg!: string;
  // id!: number;
  // location!: string;
  // address!: string;

  public static isSubscribed = false
  public static queueMessages: Set<Alert> = new Set();

  public static async observeMessages () {
    if (!AAlert.isSubscribed) return
    const messages = await AAlert.list({
      startTime: DateTime.now().toUTC().plus({ hours: -2 }).toFormat("yyyy-LL-dd'T'HH:mm:ss'Z'"),
      endTime: DateTime.now().toUTC().toFormat("yyyy-LL-dd'T'HH:mm:ss'Z'"),
      uniqueIds: StreamaxJSSdk.actions.AUnit.unit_ids.join(','),
      alarmTypes: ''
    }, { raw: true })
    if (this.queueMessages.size === 0) {
      this.queueMessages = new Set(messages)
    } else {
      const newEvents = messages.filter(event => ![...this.queueMessages].some(alert => alert.alarmId === event.alarmId));
      // Añadir los nuevos eventos a queueMessages
      newEvents.forEach(event => this.queueMessages.add(event));
      if (newEvents.length > 0) StreamaxJSSdk.event.emit('ALERT_MESSAGE_CHANGED', newEvents)
    }

    // @ts-ignore
    // const lastMessage = messages?.at(0)
    // // @ts-ignore
    // const lastCurrentMessage = AAlert.queueMessages?.at(0)
    // const haveBothMessages = lastMessage && lastCurrentMessage

    // if (haveBothMessages && lastMessage?.time !== lastCurrentMessage?.time) {
    //   MaponSDK.event.emit('ALERT_MESSAGE_CHANGED', lastMessage)
    // }

    // AAlert.queueMessages = messages
  }

  public static addEventListener (type: 'ALERT_MESSAGE_CHANGED', callback: (alerts: Alert[]) => void) {
    switch (type) {
      case 'ALERT_MESSAGE_CHANGED':
        AAlert.isSubscribed = true
        StreamaxJSSdk.event.addListener(type, callback)
        break
    }
  }

  public static removeEventListener (type: 'ALERT_MESSAGE_CHANGED', callback: (alert: Alert) => void) {
    switch (type) {
      case 'ALERT_MESSAGE_CHANGED':
        AAlert.isSubscribed = false
        StreamaxJSSdk.event.removeListener(type, callback)
        break
    }
  }

  public static async list (params: QueryHistoryAlarmDTO, { raw } = { raw: false }): Promise<AAlert[]> {
    const { data } = await StreamaxJSSdk.axios<RequestData<QueryHistoryAlarmDTO>>({
      url: '/alarms',
      method: 'GET',
      params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    // return data
    if (!raw) {
    // @ts-ignore
      const aAlerts = data.data.map((obj) => new AAlert({ data: obj }))
      return aAlerts
    } else {
      // @ts-ignore
      return data.data
    }
  }

  isUpdated (data: any): boolean {
    return false
  }
}
