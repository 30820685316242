import { CAMERA_PLATAFORM } from '../catalogs/CAMERA_PLATAFORM'
import UnitConsoleData from '../packages/ReportItem/ReportItemData'
import { State } from './state'

export default {
  getCamerasWithUnits (state: State, getters: any, rootState: any) {
    return (rootState.travel.unitsRegisters as UnitConsoleData<unknown, unknown>[]).map(obj => obj.cameras.map(cam => ({ camera: cam, unitconsole: obj }))).flat()
  },
  getHikvisionCamerasWithUnits (state: State, getters: any, rootState: any) {
    return (rootState.travel.unitsRegisters as UnitConsoleData<unknown, unknown>[]).map(obj => obj.cameras.reduce((accumulator, cam) => {
      if (cam.cpf_type === CAMERA_PLATAFORM.HIKVISION) {
        // @ts-ignore
        accumulator.push({ label: cam.cpf_name, value: cam.cpt_properties?.EhomeID, icon: '/images/truck.png' })
      }
      return accumulator
    }, [])).flat()
  },
  getSheduledReports (state: State) {
    return state.sheduled_reports
  },
  getNotifications (state: State) {
    return state.notifications
  },
  getNotificationsNoRead (state: State) {
    return state.notifications.filter(notification => !notification.view).length
  }
}
