import DeviceItem from '../item/Device.item'
import IDevice, { AddDeviceReqDTO, DeleteDeviceReqDTO, DeviceUpdateReqDTO, QueryDeviceReqDTO } from '../interfaces/Device.interface'
import StreamaxJSSdk from '..'
import ResourceApi from './ResourceApi.base'

export default class DeviceApi extends ResourceApi<IDevice, AddDeviceReqDTO, DeviceUpdateReqDTO, QueryDeviceReqDTO, DeleteDeviceReqDTO> {
    apiPlaceUrl: string;
    itemClass: typeof DeviceItem;

    constructor (sdk: StreamaxJSSdk) {
      super(sdk)
      this.apiPlaceUrl = '/devices'
      this.itemClass = DeviceItem
    }

    /**
     * This API is used to add a device through the device serial number
     */
    public async create (params: AddDeviceReqDTO) {
      const data = await this.createUtil(params)
      return data
    }

    /**
     * This API is used to modify the fleetId or lanWan of existing device according to uniqueId.
     * At least one of fleetId and lanWan has to be provided.
     */
    public async update (id: string, params: DeviceUpdateReqDTO) {
      const data = await this.updateUtil(id, params)
      return data
    }

    /**
     * This API is used to delete devices based on the device serial number.
     *  Batch deletion is supported, and a maximum of 50 devices can be deleted in a batch.
     */
    public async delete (params: DeleteDeviceReqDTO) {
      const data = await this.deleteUtil(params)
      return data
    }

    /**
     * This API is used to obtain the device list information.
     * The device information also contains the real-time online status.
     * The list is displayed by default in ascending order of the device serial number.
    */
    public async list (params?: QueryDeviceReqDTO) {
      const data = await this.listUtil(params)
      return data
    }
}
