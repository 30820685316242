import { ActionContext } from 'vuex'
import { serializeError } from 'serialize-error'
import { State } from './state'
import actionsStoreParts from './modules/store_parts/actions'
import actionsStore from './modules/store/actions'
import actionsPartsMaterials from './modules/parts_materials/actions'
import actionsMeasurement from './modules/measurement/actions'
import actionsProviderInventory from './modules/provider_inventory/actions'
import actionsEntryConcept from './modules/entry_concept/actions'
import actionsEntryType from './modules/entry_type/actions'
import actionsPurchaseOrder from './modules/purchase_order/actions'
import actionsTireInventory from './modules/tire_inventory/actions'
import actionsEntryStore from './modules/entry_store/actions'
import actionsRequisition from './modules/requisition/actions'
import actionsDepartment from './modules/department/actions'
import actionsBatteryInventory from './modules/battery_inventory/actions'

export default {
  async init ({ commit, dispatch }: ActionContext<State, any>) {
    console.log('NADA')
  },
  ...actionsStoreParts,
  ...actionsStore,
  ...actionsPartsMaterials,
  ...actionsMeasurement,
  ...actionsProviderInventory,
  ...actionsEntryConcept,
  ...actionsEntryType,
  ...actionsPurchaseOrder,
  ...actionsTireInventory,
  ...actionsEntryStore,
  ...actionsRequisition,
  ...actionsDepartment,
  ...actionsBatteryInventory
}
