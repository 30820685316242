import { State } from '../../state'
import { ActionContext } from 'vuex'
import { ILoadFuel } from '../../../../classes/Fuel/LoadFuel'
import objToFormData from '@/utils/objToFormData'
import getGastosByMonth from '@/utils/getGastosByMonth'
const actions = {
  async loadLoadfuel ({ dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/loadfuel/get/date-range',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    // Determinar la zona horaria del cliente
    try {
      data.monthly_costs = getGastosByMonth(data)
    } catch (error) {
      data.monthly_costs = []
      console.error(error)
    }
    return data
  },
  async loadLoadfuelLast ({ dispatch }: ActionContext<State, any>, unitconsole_id: number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/loadfuel/get_last/${unitconsole_id}/`,
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async createLoadfuel ({ dispatch }: ActionContext<State, any>, payload:ILoadFuel) {
    const formData = objToFormData(payload)
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/loadfuel/',
        method: 'POST',
        data: formData
      },
      { root: true }
    )
    // commit('types/PUSH_PROVIDERS', data, { root: true })
  },
  async deleteLoadfuel ({ dispatch }: ActionContext<State, any>, loadfuel_id:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/loadfuel/delete/${loadfuel_id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    // commit('types/PUSH_PROVIDERS', data, { root: true })
  },
  async dashboardLoadFuel ({ dispatch }: ActionContext<State, any>, payload: { date_range: any, ids_units: [] }) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/loadfuel/dashboard',
        method: 'POST',
        data: { ...payload, timezone }
      },
      { root: true }
    )
    return data
    // commit('types/PUSH_PROVIDERS', data, { root: true })
  }
  // async updateProvider ({ commit, dispatch }: ActionContext<State, any>, payload:IProvider) {
  //   const { data } = await dispatch(
  //     'app/axios',
  //     {
  //       url: `/apis/provider/${payload.id}/`,
  //       method: 'PUT',
  //       data: { ...payload }
  //     },
  //     { root: true }
  //   )
  //   commit('types/UPDATE_PROVIDER', data, { root: true })
  // },
  // async deleteProvider ({ commit, dispatch }: ActionContext<State, any>, payload:IProvider) {
  //   const { data } = await dispatch(
  //     'app/axios',
  //     {
  //       url: `/apis/provider/${payload.id}/`,
  //       method: 'DELETE'
  //     },
  //     { root: true }
  //   )
  //   commit('types/DELETE_PROVIDER', data, { root: true })
  // }
}
export default actions
