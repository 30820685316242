
import { ActionContext } from 'vuex'
import { State } from './state'
import { ICustomer } from '@/classes/Facturacion/ICustomer'
import buildQueryString from '@/utils/buildQueryString'

const URL_ACTION = '/apis/quotation/'
const actions = {
  async loadAll ({ commit, dispatch }: ActionContext<State, any>, options: { search: '', note_sale: '', page: 1 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: options ? URL_ACTION + buildQueryString(options) : URL_ACTION,
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async getById ({ commit, dispatch }: ActionContext<State, any>, id:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${id}`,
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async create ({ commit, dispatch }: ActionContext<State, any>, payload: ICustomer) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    // commit('PUSH', data)
    return data
  },
  async update ({ commit, dispatch }: ActionContext<State, any>, payload: ICustomer) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    // commit('UPDATE', data)
    return data
  }
}
export default actions
