/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import GlobalFunctions from './plugins/GlobalFunctions'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import PortalVue from 'portal-vue'
// @ts-ignore
import LoadScript from 'vue-plugin-load-script'
import Toast from 'vue-toastification'
import Toasted from 'vue-toasted'
// @ts-ignore
import DatetimePicker from 'vuetify-datetime-picker'
import { serializeError } from 'serialize-error'
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css'
import 'leaflet/dist/leaflet.css'
import './assets/sass/index.sass'
import './plugins/FormRules'
import Fragment from 'vue-fragment'
import '@mdi/font/css/materialdesignicons.min.css'
import '../node_modules/material-icons/iconfont/material-icons.scss'
import { Icon } from 'leaflet'
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import 'dual-listbox/dist/dual-listbox.js'
import 'dual-listbox/dist/dual-listbox.css'
import './plugins/videojs.ts'
import 'leaflet-rotatedmarker'
import '../src/assets/style.css'
// @ts-ignore
import VuetifyDaterangePicker from 'vuetify-daterange-picker'
// @ts-ignore
import VueGoodTable from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

import 'leaflet-polylinedecorator/dist/leaflet.polylineDecorator.js'
import 'vuetify-daterange-picker/dist/vuetify-daterange-picker.css'
// @ts-ignore
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

Vue.use(VueAxios, axios)
// @ts-ignore
Vue.use(LoadScript)
Vue.use(Toast)
Vue.use(Toasted)
Vue.use(GlobalFunctions)
Vue.use(DatetimePicker)
Vue.use(Fragment.Plugin)
Vue.use(PortalVue)
Vue.use(VueGoodTable)

// main.js

Vue.use(VuetifyDaterangePicker)

Vue.config.productionTip = false

new Vue({
  router,
  // @ts-ignore
  vuetify,
  store,
  mounted () {
    this.$loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyAgS-koZgM4uRyOHUCmIryX1FlJCL4zoEM&libraries=places')
      .then(() => {
        console.log('Google Maps script loaded successfully')
      })
      .catch(() => {
        console.error('Failed to load Google Maps script')
      })
    window.onbeforeunload = function (e) {
      e = e || window.event

      // For IE and Firefox prior to version 4
      if (e) {
        e.returnValue = 'Sure?'
      }

      // For Safari
      return 'Sure?'
    }
  },
  errorCaptured (err, vm, info) {
    this.$store.commit('app/ADD_LOG', { title: 'ERROR_CAPTURED', color: 'error', message: info, payload: serializeError(err) })
    return true
  },
  warnHandler (msg: any, vm: any, trace: any) {
    this.$store.commit('app/ADD_LOG', { title: 'WARN_CAPTURED', color: 'warning', message: msg, payload: JSON.stringify(trace) })
    return true
  },
  render: h => h(App)
}).$mount('#app')
