import { RouteConfig } from 'vue-router'
import store from '@/store/index'

const Login: RouteConfig[] = [{
  path: '/',
  name: 'Login',
  component: () => import('@/pages/Login.vue'),
  beforeEnter (to, from, next) {
    if (store.state.auth.is_auth) {
      next({
        replace: true,
        name: 'Console'
      })
    } else {
      next()
    }
  }
},
{
  path: '/cms/login',
  name: 'LoginCMS',
  component: () => import('@/pages/LoginCMS.vue'),
  beforeEnter (to, from, next) {
    if (store.state.auth.is_auth) {
      next({
        replace: true,
        name: 'Cms'
      })
    } else {
      next()
    }
  }
}]

export default Login
