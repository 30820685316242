<template>
  <div>
    <OverlayLoader />
    <LoaderDialog />
    <router-view></router-view>
  </div>
</template>

<script>
import LoaderDialog from './components/LoaderDialog.vue'
import { requestPermission, fetchToken, messaging } from '@/firebase'
import { onMessage } from 'firebase/messaging'
import OverlayLoader from './components/OverlayLoader.vue'

export default {
  components: {
    LoaderDialog,
    OverlayLoader
  },
  methods: {
    async initializeFirebase () {
      // await requestPermission()
      // onMessage(messaging, (payload) => {
      //   console.log('Message received. ', payload)
      //   console.log('DESDE AQUIIIIIIIIIIIIIIIIIIIII')
      // })
    }
  },
  mounted () {
    this.initializeFirebase()
  }
}
</script>
