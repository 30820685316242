const state = () => ({
  drawer: true,
  view: 2,
  logs: [],
  config: {
    dialog: false
  },
  loader: {
    loader: false,
    message: ''
  },
  overlay: false,
  socket: null,
  reload: false,
  drawerNotify: false,
  allow_image: (process.env.VUE_APP_ALLOW_IMAGE === 'true'),
  socket_travel: null,
  socket_system: null,
  notifications: [],
  openManager: false
})

export type State = ReturnType<typeof state>;

export default state
