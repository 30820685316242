
import { State } from './state'
import { ActionContext } from 'vuex'
import { USER_TYPES } from '../auth/USER_TYPES'
import { RESOURCES } from '@/store/resources/RESOURCES'

export default {
  async init ({ commit, dispatch, rootGetters }: ActionContext<State, any>) {
    try {
      commit(
        'app/UPDATE_LOADER',
        {
          loader: true,
          message: 'Descargando Recursos'
        },
        { root: true }
      )
      if (rootGetters['auth/roleId'] === USER_TYPES.MONITOREO) {
        await dispatch('loadCatalogs')
      }
    } catch (error) {
      console.error(error)
      commit(
        'app/ADD_LOG',
        {
          title: 'OBTENIENDO RECURSOS',
          color: 'error',
          message: error
        },
        { root: true }
      )
    } finally {
      commit(
        'app/UPDATE_LOADER',
        {
          loader: false,
          message: 'Descargando Recursos'
        },
        { root: true }
      )
    }
  },
  async loadCatalogs ({ dispatch }: ActionContext<State, any>) {
    await dispatch('loadsDashboard')
  },
  async loadsDashboard ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/get_travel/status/',
        method: 'GET'
      },
      { root: true }
    )
    commit(
      'resources/SET_RESOURCES',
      {
        resources: data[0],
        rType: RESOURCES.DASHBOARD
      },
      { root: true }
    )
  }
}
